export interface GalleryProject {
  id: number;
  title: string;
  description: string;
  technology: string;
  tags: string[];
  images: string[];
}

export const galleryProjects: GalleryProject[] = [
  {
    id: 1,
    title: 'BJD лиса',
    description: 'Миниатюрная bjd лисичка. Состоит из 30 деталей!',
    technology: 'SLA',
    tags: ['SLA', 'painting'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/lisa1?updatedAt=1735937869087',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/lisa3?updatedAt=1735937869012',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/lisa2?updatedAt=1735937869127',
    ],
  },
  {
    id: 2,
    title: 'BJD куклы',
    description: 'Мастер-модели для подвижных шарнирных кукол',
    technology: 'SLA',
    tags: ['SLA', 'painting'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/IMG_20250103_222656.jpg?updatedAt=1735937868715',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/IMG_20250103_222940.jpg?updatedAt=1735937869264',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/bjd/IMG_20250103_223021.jpg?updatedAt=1735937869088',
    ],
  },
  {
    id: 3,
    title: 'Миниатюрки',
    description: 'Различные фигурки для настольных игр',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222023.jpg?updatedAt=1735938148118',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222004.jpg?updatedAt=1735938147898',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_183042.jpg?updatedAt=1735938150114',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222048.jpg?updatedAt=1735938147929',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20230131_192631.jpg?updatedAt=1735938148289',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20230131_192640.jpg?updatedAt=1735938148360',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222213.jpg?updatedAt=1735938147973',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_234414.jpg?updatedAt=1735938148060',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/mini/IMG_20250103_222112.jpg?updatedAt=1735938147988',
    ],
  },
  {
    id: 4,
    title: 'Сувенирная фигурка',
    description: 'Сканирование и печать человеческой головы ',
    technology: 'SLA',
    tags: ['SLA'],
    images: [
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183319.jpg?updatedAt=1735938046021',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183400.jpg?updatedAt=1735938045935',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183333.jpg?updatedAt=1735938045969',
      'https://ik.imagekit.io/5pc35hfli/%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B%20%D1%80%D0%B0%D0%B1%D0%BE%D1%82/%D0%BB%D1%8E%D0%B4%D0%B8%20/IMG_20250103_183302.jpg?updatedAt=1735938045648',
    ],
  },
];
