import React, { useState, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ContactSection from '../components/ContactSection';
import GalleryFilters from '../components/GalleryFilters';
import { useSearchParams } from 'react-router-dom';
import { galleryProjects } from '../data/galleryData';

function Gallery() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = searchParams.get('filter') || 'all';
  const [activeFilter, setActiveFilter] = useState(initialFilter);

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);
    if (filter === 'all') {
      setSearchParams({});
    } else {
      setSearchParams({ filter });
    }
  };

  const filteredProjects = useMemo(() => {
    if (activeFilter === 'all') return galleryProjects;
    return galleryProjects.filter((project) => project.tags.includes(activeFilter));
  }, [activeFilter]);

  return (
    <div className="space-y-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <h1 className="text-4xl font-bold mb-12 text-center">Галерея работ</h1>

        <GalleryFilters
          activeFilter={activeFilter}
          onFilterChange={handleFilterChange}
        />

        {filteredProjects.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-gray-600 text-lg">
              По выбранным фильтрам ничего не найдено
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredProjects.map((project) => (
              <div
                key={project.id}
                className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform hover:scale-105"
              >
                <div className="relative aspect-square">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    navigation={{
                      prevEl: `.prev-${project.id}`,
                      nextEl: `.next-${project.id}`,
                    }}
                    pagination={{ clickable: true }}
                    loop={true}
                    className="h-full w-full"
                  >
                    {project.images.map((image, index) => (
                      <SwiperSlide key={index} className="aspect-square">
                        <img
                          src={image}
                          alt={`${project.title} - вид ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <button
                    className={`prev-${project.id} absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-1.5 rounded-full hover:bg-white transition-colors`}
                  >
                    <ChevronLeft className="w-4 h-4" />
                  </button>
                  <button
                    className={`next-${project.id} absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-white/80 p-1.5 rounded-full hover:bg-white transition-colors`}
                  >
                    <ChevronRight className="w-4 h-4" />
                  </button>
                </div>

                <div className="p-4">
                  <div className="flex flex-wrap gap-2 mb-3">
                    {project.tags.map((tag) => (
                      <span
                        key={tag}
                        className="inline-block px-3 py-1 text-sm font-medium bg-secondary/10 text-secondary rounded-full"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  <h3 className="text-lg font-semibold mb-2">{project.title}</h3>
                  <p className="text-sm text-gray-600">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <ContactSection />
    </div>
  );
}

export default Gallery;